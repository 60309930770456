import Image from 'next/image'
import appStoresImage from '../../../assets/landing/AppleStoreImage.png'
import appStoreButtonImage from '../../../assets/landing/appStoreButton.svg'
import googlePlayButtonImage from '../../../assets/landing/googlePlayButton.svg'
import fiveStarsImg from '../../../assets/seo/fiveStars.svg'
import reviewsImg from '../../../assets/seo/reviews.svg'
import {
  AdaptibleIosImage,
  AppStoreReviewBlock,
  GradientButton,
  MobileOnlyBlock,
  MobileViewMoreSection,
  MoreButton,
  ReviewAuthor,
  ReviewBlock,
  ReviewDesktopOnlyBlock,
  ReviewPageSectionContainer,
  ReviewsFlex,
  ReviewsList,
  ReviewsSection,
  StarsImage,
  StoreButtonsContainer
} from '../Create.styles'

const HomeReview = () => {
  return (
    <ReviewPageSectionContainer>
      <ReviewsSection>
        <h2>Our Users Love Our Interior Design App</h2>
        <ReviewsFlex>
          <ReviewsList>
            <MobileOnlyBlock>
              <AppStoreReviewBlock>
                <img src={reviewsImg} alt="" />
                <MoreButton>More</MoreButton>
              </AppStoreReviewBlock>
            </MobileOnlyBlock>
            <ReviewBlock>
              <ReviewAuthor>-coola</ReviewAuthor>
              <h3>Therapy app!</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                I’ve been a member since April 2021. DecorMatters is my favorite “therapy” app and house design game. I love being creative, seeing beautiful designs, and being part of an encouraging community of interior decorators. Best interior
                design app!
              </p>
            </ReviewBlock>
            <ReviewBlock>
              <ReviewAuthor>-Ravenrosey</ReviewAuthor>
              <h3>Super enjoyable design game</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                I've been playing DecorMatters for over 3 years. The creators of this home design game continually make it better with the best decor items available and fun design challenges. I highly recommend the yearly membership, it's worth
                every penny. This game is my go-to when I want to let my creative juices flow and relax. I've also made friends here with other players. This has been my favorite room decorating game to play, and I'm still having just as much fun as
                when I first started 3 years ago. I highly recommend if you like decorating and being creative. The other players definitely will lift you up with encouraging compliments on your designs.
              </p>
            </ReviewBlock>
            <ReviewBlock>
              <ReviewAuthor>-NioakaBayos</ReviewAuthor>
              <h3>Best design app!</h3>
              <StarsImage>
                <Image src={fiveStarsImg} alt="Five stars" width={101} height={14} />
              </StarsImage>
              <p>
                This is the ONLY real design app that allows you to actually design! With DM, YOU are truly designing your space. You are the designer and room planner. You can choose from their many templates and challenges, or you can upload your
                own space! You can use the game's endless supply of high-quality furniture, or you can upload your own! You can upload your own art, flowers, outdoor views, windows, walls, wallpapers, fireplaces…you name it! You have that sort of
                freedom with this home game. In fact, I hate to call it a game, because it’s so much more than that. My only complaint, is that I can spend all day on the app, so I have to try and control myself! The yearly subscription is very
                affordable compared to many other games that charge an arm and a leg for monthly subscriptions. I can’t say enough about this one, and it only improves as the years go by!
              </p>
            </ReviewBlock>
          </ReviewsList>
          <ReviewDesktopOnlyBlock>
            <Image width={553} height={714} src={appStoresImage} />
            <StoreButtonsContainer>
              <a href="https://apps.apple.com/us/app/decor-matters-design-shop/id1168709467">
                <img src={appStoreButtonImage} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.decormatters.prod">
                <img src={googlePlayButtonImage} alt="" />
              </a>
            </StoreButtonsContainer>
          </ReviewDesktopOnlyBlock>
          <AdaptibleIosImage />
        </ReviewsFlex>
        <MobileViewMoreSection as="a" target="_blank" href="https://decormatters.app.link/WTHVetOFCMb">
          <GradientButton>Download Our App</GradientButton>
        </MobileViewMoreSection>
      </ReviewsSection>
    </ReviewPageSectionContainer>
  )
}

export default HomeReview
